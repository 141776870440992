import React from 'react';
import battle from '../../assets/battle.jpg';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Let&apos;s ignite the whole world with knowledge</h1>
      <p><i>&#34;The greatest want of the world is the want of men - men who will not be bought or sold, men who in their inmost souls are true and honest, men who do not fear to call sin by its right name, men whose conscience is as true to duty as the needle to the pole, men who will stand for the right though the heavens fall.&#34;</i> - E.G.White</p>
      {/*
      <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Join us</button>
      </div>

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div>
      */}
    </div>

    <div className="gpt3__header-image">
      <img src={battle} />
    </div>
  </div>
);

export default Header;
