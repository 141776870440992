import React, { useState } from 'react';
import axios from 'axios';
import em from '../../assets/email.png';
import './contact.css';
import { client } from '../../client';


const Contact = () => {

  const [formData, setFormData] = useState({ name: '', email: '', message: ''});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);


  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = () => {

    setLoading(true);

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message,
    };

    client.create(contact).then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    })
  }

  /*
  const handleSubmit = () => {

    setLoading(true);

    const contact = {
      
      name: name,
      email: email,
      message: message,
    };

    axios.post("https://project-gospel.com/contact", {
      
      name: name,
      email: email,
      message: message,
    })
    .then(function (response) {
      console.log(response);
      setLoading(false);
      setIsFormSubmitted(true);
    })
    .catch(function (error) {
      console.log(error);
    });
    
  }
  */

  return (

    <div className='app__footer' id='contact'>

      <h2 className='head-text'>Get in touch</h2>

        <div className='app__footer-cards'>
        

          <div className='app__footer-card'>
            <img src={em} alt='email' />
            <a href='info@project-gospel.com' className='p-text'>info@project-gospel.com</a>
          </div>


      </div>

{!isFormSubmitted ? 
      <div className='app__footer-form'>
          <div className='app__flex'>
            <input className='p-text' name='name' type='text' placeholder='Your name' value={name} onChange={handleChangeInput} />
          </div>

          <div className='app__flex'>
            <input className='p-text' name='email' type='text' placeholder='Your email' value={email} onChange={handleChangeInput} />
          </div>

          <div>
            <textarea className='p-text' placeholder='Your message' value={message} name='message' onChange={handleChangeInput} />
          </div>

          <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Sending' : 'Send'}</button>
    </div>
    
: <div>
  <h3 className='head-text'>Thank you for getting in touch</h3>
  </div>}
    </div>
  );

};

export default Contact;