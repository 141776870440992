import './App.css';
import React from 'react';
import { Footer, Blog, Features, WhatGPT3, Header } from './containers';
import { Navbar } from './components';

function App() {
  return (
    <div className = "App">
    <div className = "gradient__bg">
     <Navbar />
    <Header />
    </div> 
    { /* <Brand /> */ } 
    <WhatGPT3 />
    <Features /> {
        /* <Possibility />
            <CTA />*/} 
    <Blog />
    <Footer />
</div>
  );
}

export default App;
