import React from 'react';
import Article from '../../components/article/Article';
import main from '../../assets/nailgc.jpg';
import sab from '../../assets/sabbath.jpg';
import sun from '../../assets/sun.jpg';
import final from '../../assets/final.jpg';
import origin from '../../assets/origin.jpg';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">Other things<br /> you might find interesting.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={main} url="https://www.youtube.com/watch?v=LF6pjueQsHI&t=1s" text="The Great Controversy and the story behind it" />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={sab} url="https://www.youtube.com/watch?v=2t2nU85xEzo" text="The Truth about the Day of rest" />
        <Article imgUrl={sun} url="https://www.youtube.com/watch?v=ESqrgnSbIJ0" text="Is Sunday Really Sacred" />
        <Article imgUrl={final} url="https://www.youtube.com/watch?v=ZuvXW3I1BaM" text="Bible prophecy" />
        <Article imgUrl={origin} date="https://www.youtube.com/watch?v=JqLIndMA9Ks" text="Cosmic Conflict: The Origin of Evil" />
      </div>
    </div>
  </div>
);

export default Blog;
