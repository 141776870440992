import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'PROJECT-GOSPEL',
    text: 'We are passionate Christians who would love to share the good news with as many peope as possible. We all live in a world, where the good, positive, noble, moral, pure and true are mocked and despised, where news of terror, fear, sadness, death, anxiety, worry, depression is all we hear and read. When we read the Great Controversy we were filled with hope and assurance and found asnwers to a lot of questions.',
  },
  {
    title: '',
    text: 'Seeing our friends and family beset with fears for the future, worrying about finances, material possessions, careers, the environment etc. it made us realise that we are privileged to have the truth and knowledge that while everything is getting from bad to worse, one day Jesus will come and put an end to all sorrow, sadness, pain and depression forever. Therefore, we have a fervent desire to share this with everyone and this is what PROJECT-GOSPEL is all about, i.e. breaking the GOOD NEWS - Jesus is coming again!',
  },
  {
    title: '',
    text: 'The project\'s main goal is to distribute the book "The Great Controversy" to every person in Liverpool. The book has transformed the lives of the project\'s members and they believe it has the power to transform others\' lives. They believe in the second coming of Jesus and aim to fulfill the Bible\'s prophecy in Matthew 24:14 by sharing the gospel with everyone. The book provides historical insight into the dark ages and the Reformation and is factually accurate. It also contains valuable truths that are essential for everyone to know.'
  }
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The project</h1>
      <p>More projects coming soon...</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
