import React from 'react';
import logo from '../../assets/pro.png';
import Contact from '../contact/Contact';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Do you want to know more what the future holds</h1>
    </div>

    <Contact />

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="gpt3_logo" />
        <p>Liverpool UK, <br /> All Rights Reserved</p>
      </div>
      {/* 
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Useful links</p>
        <p>Resources</p>
        <p>Contact</p>
      </div>
      
      <div className="gpt3__footer-links_div">
        <h4>About</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      */}
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>PROJECT-GOSPEL</p>
        <p>info@project-gospel.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 PROJECT-GOSPEL. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
