import React from 'react';
import './article.css';

const Article = ({ imgUrl, url, text }) => (
  <div className="gpt3__blog-container_article">
    <div className="gpt3__blog-container_article-image">
      <a href={url}><img src={imgUrl} alt="blog_image" /></a>
    </div>
    <div className="gpt3__blog-container_article-content">
      <div>
        <h3>{text}</h3>
      </div>
      <p>Check it out</p>
    </div>
  </div>
);

export default Article;
