import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is The Great Controversy" text="As Christians, we believe that all humanity is now involved in a great controversy between Christ and Satan regarding the character of God, His law, and His sovereignty over the universe. This conflict originated in heaven when a created being, endowed with freedom of choice, in self-exaltation became Satan, God’s adversary, and led into rebellion a portion of the angels. He introduced the spirit of rebellion into this world when he led Adam and Eve into sin." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The greatest story ever told; one of the main characters is YOU</h1>
      {/*<p>Explore the Library</p>*/}
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="How it all began" text="Right from the beginning, the great controversy begins with war in heaven, explaining how sin entered our world and Earth became the battleground of the Great Controversy. All the pain and loss humanity suffers is a result of that war. " />
      <Feature title="Are we involved" text="Earth is the place where good and evil are put on full display so each person can judge for themselves who they are going to follow: Satan and his selfishness, or God and His love." />
      <Feature title="Who is winning" text="The entire universe has its eyes on Earth, waiting to see how this war plays out. Will God’s good and just character finally be vindicated? Fortunately, we already know how this war is going to end. God told us Himself in the book of Revelation, explaining how Satan and everyone he’s deceived will one day be wiped out and the world will be made new." />
    </div>
  </div>
);

export default WhatGPT3;
