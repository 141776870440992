import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';


export const client = createClient({
    projectId: 'jw9gsu70',
    dataset: 'production',
    apiVersion: '2023-02-01',
    useCdn: true,
    token: 'skcXCiFSszMql58YJbhw4yGFuuuZyGBmJP9W2hFPVJM22BD2gfsZnLSWq7iZaWIboVn7DnhnsZ6rPbl3Fsg8p8uRxo4bHjMdPv9Gefjd6OnB1vZky6T8jM28jBcFqcowGWs5lHE9NCrv1FKzdXd3S80XzFaGObPOziChZfUi6OQksyVy8EK9',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);